import { ReactComponent as DropDownOutlined } from "@assets/icons/dropdown-icon.svg";
import { Image, Space, notification } from "antd";
import MainLayout from "@components/layouts/MainLayout";

import { Col, Row, Form, Button, Select, Divider, Typography } from "antd";

import {
  Field,
  useFormik,
  FieldArray,
  ErrorMessage,
  FormikProvider,
} from "formik";

import { forwardRef, useEffect, useState } from "react";
import * as yup from "yup";

import "./styles.css";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "@config/storeConfig";
import { Slide, toast } from "react-toastify";
import { Link, useParams } from "react-router-dom";
import {
  FormikRadioInput,
  FormikRangePicker,
  FormikTextField,
  NewFormikRadioInput,
} from "@components/inputFeilds/InputFeilds";

import ImageUpload from "@components/imageUpload/ImageUpload";
import {
  fetchInrangeChallenges,
  fetchInrangeRanges,
} from "@slices/inrangeSlice";
import { fetchCampaign, updateCampaign } from "@slices/campaignsSlice";
import {
  fetchFlightDistributions,
  fetchFlightNames,
} from "@slices/flightsSlice";

const { Item } = Form;
const { Option } = Select;
const { Text } = Typography;

const ViewCampaign = () => {
  const params = useParams();
  const currentCampaignId = params?.id;
  const dispatch = useDispatch<AppDispatch>();
  const [posterBase, setPosterBase] = useState<any>("");
  const [logoBase, setLogoBase] = useState<any>("");
  const [selectedChallenge, setSelectedChallenge] = useState<any>({});
  const liveCampaignData = useSelector(
    (state: any) => state.campaigns.campaign.data
  );
  const rangesFromRedux = useSelector(
    (state: RootState) => state.inrange.inrangeRanges
  );

  const challengesFromRedux = useSelector(
    (state: RootState) => state.inrange.inrageChallenges
  );

  const rangesNamesOptions =
    rangesFromRedux &&
    rangesFromRedux.map((range: any) => ({
      name: range.name,
      id: range.rangeId,
    }));

  const selectedRangesFromApi =
    liveCampaignData &&
    liveCampaignData.ranges &&
    Array.isArray(liveCampaignData.ranges) &&
    liveCampaignData.ranges.length > 0 &&
    liveCampaignData.ranges.map((range: any) => range.rangeId);

  const selectedChallenges =
    liveCampaignData &&
    liveCampaignData.challenge &&
    liveCampaignData.challenge.challengeId
      ? liveCampaignData.challenge.challengeId
      : "";

  const challengeNamesOptions =
    challengesFromRedux &&
    challengesFromRedux.map((challenge: any) => ({
      name: challenge.name,
      id: challenge.challengeId,
    }));

  const flightDistributionsFromRedux = useSelector(
    (state: any) => state.flights.flightDistributions.data
  );
  const flightNamesFromRedux = useSelector(
    (state: any) => state.flights.flightNames.data
  );

  const flightDistributionOptions = flightDistributionsFromRedux?.filter(
    (flightDistribution: any) => ({
      name: flightDistribution.name,
      id: flightDistribution.id,
    })
  );

  const inrageFlightNameOptions = flightNamesFromRedux?.map((flight: any) => ({
    name: flight.name,
    id: flight.id,
    min_hcp: flight.min_hcp === -12345 ? "-" : flight.min_hcp,
    max_hcp: flight.max_hcp === 12345 ? "-" : flight.max_hcp,
    percentage: flight.percentage,
  }));

  const handleViewCampaignFormSubmit = async (values: any) => {
    await dispatch(
      updateCampaign({ campaignData: values, campaignId: currentCampaignId })
    );
  };

  // Writing formik logic right here as it is very specific
  const formikMain: any = useFormik({
    enableReinitialize: true,
    initialValues: {
      name:
        liveCampaignData && liveCampaignData.name ? liveCampaignData.name : "",
      ranges:
        Array.isArray(selectedRangesFromApi) && selectedRangesFromApi.length > 0
          ? selectedRangesFromApi
          : [],
      challenges: selectedChallenges ? selectedChallenges : "",
      startDate:
        liveCampaignData && liveCampaignData.start_date
          ? liveCampaignData.start_date
          : "",
      campaignDates:
        liveCampaignData &&
        liveCampaignData.start_date &&
        liveCampaignData.end_date
          ? [liveCampaignData.start_date, liveCampaignData.end_date]
          : "",
      endDate:
        liveCampaignData && liveCampaignData.end_date
          ? liveCampaignData.end_date
          : "",
      priority:
        liveCampaignData && liveCampaignData.priority
          ? liveCampaignData.priority
          : "",
      flights:
        liveCampaignData && liveCampaignData.flights
          ? liveCampaignData.flights
          : false,
      type:
        liveCampaignData && liveCampaignData.campaign_type
          ? liveCampaignData.campaign_type
          : "",
      prizes:
        liveCampaignData && liveCampaignData.prizes
          ? liveCampaignData.prizes
          : "",
      description:
        liveCampaignData && liveCampaignData.description
          ? liveCampaignData.description
          : "",
      smash_points:
        liveCampaignData && liveCampaignData.smash_points
          ? liveCampaignData.smash_points
          : "",
      smash_cash:
        liveCampaignData && liveCampaignData.smash_cash
          ? liveCampaignData.smash_cash
          : "",
      rewards:
        liveCampaignData && liveCampaignData.rewards
          ? liveCampaignData.rewards
          : null,
      scoring: undefined,
      campaign_poster: "",
      signup_banner: "",
      rules:
        liveCampaignData && liveCampaignData.rules
          ? liveCampaignData.rules
          : "",
      flight_distribution_id:
        liveCampaignData && liveCampaignData.flight_distribution_id
          ? liveCampaignData.flight_distribution_id
          : "",
      cost:
        liveCampaignData && liveCampaignData.cost ? liveCampaignData.cost : "",
      event_entry: liveCampaignData && liveCampaignData.event_entry,
      thirdPartyAuthUrl:
        liveCampaignData &&
        liveCampaignData.meta &&
        liveCampaignData.meta.thirdPartyAuthUrl
          ? liveCampaignData.meta.thirdPartyAuthUrl
          : "https://staging.smasherson.com/signin?v=1",

      ...(liveCampaignData &&
        liveCampaignData.meta &&
        liveCampaignData.meta.maxMulligansAllowed && {
          maxMulligansAllowed: liveCampaignData.meta.maxMulligansAllowed,
        }),
      ...(liveCampaignData &&
        liveCampaignData.meta &&
        liveCampaignData.meta.maxShotsOverParAllowed && {
          maxShotsOverParAllowed: liveCampaignData.meta.maxShotsOverParAllowed,
        }),
      ...(liveCampaignData &&
        liveCampaignData.meta &&
        liveCampaignData.meta.puttingMode && {
          puttingMode: liveCampaignData.meta.puttingMode,
        }),
      ...(liveCampaignData &&
        liveCampaignData.meta &&
        liveCampaignData.meta.maxPlayers && {
          maxPlayers: liveCampaignData.meta.maxPlayers,
        }),
      ...(liveCampaignData &&
        liveCampaignData.meta &&
        liveCampaignData.meta.minPlayers && {
          minPlayers: liveCampaignData.meta.minPlayers,
        }),
      ...(liveCampaignData &&
        liveCampaignData.meta &&
        liveCampaignData.meta.holeSelection && {
          holeSelection: liveCampaignData.meta.holeSelection,
        }),
    },
    onSubmit: async (values) => {
      if (values) {
        setSubmitting(true);
        handleViewCampaignFormSubmit({
          ...values,
          poster: posterBase,
          signup_banner: logoBase,
        });
        setTimeout(() => {
          setSubmitting(false);
          resetForm();
        }, 2000);
      } else {
        notification.error({
          message: "Error while editing the campaign",
          placement: "top",
          duration: 5,
        });
      }
    },
    validationSchema: yup.object({
      name: yup.string().required("*Required Campaign name"),
      priority: yup
        .number()
        .max(100, "Priority must be less than 100")
        .required("Priority is required to create a campaign"),
      thirdPartyAuthUrl: yup
        .string()
        .required("A linking third party url is a required field"),
    }),
  });

  const {
    errors,
    getFieldProps,
    handleSubmit,
    touched,
    values,
    setFieldValue,
    setSubmitting,
    resetForm,
  } = formikMain;

  useEffect(() => {
    dispatch(fetchFlightDistributions());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (values.challenges) {
      const [finalSelectedChallenge] = challengesFromRedux.filter(
        (challenge: any) =>
          String(challenge.challengeId) === String(values.challenges)
      );
      setSelectedChallenge(finalSelectedChallenge);
    }
  }, [values, challengesFromRedux]);

  const FormikInrangeRangesSelect = forwardRef(
    (
      {
        error,
        label,
        name,
        onChange,
        onBlur,
        placeholder,
        value,
        options,
        style,
        setFieldValue,
        ...props
      }: any,
      ref
    ) => (
      <Item
        style={style}
        {...(Boolean(error) && { validateStatus: "error", help: error })}
      >
        {!!label && <p className="text-field-label">{label}</p>}

        <Select
          className="form-select-field-custom"
          suffixIcon={<DropDownOutlined />}
          placeholder={placeholder}
          ref={ref}
          mode="multiple"
          value={value}
          onChange={async (value) => {
            await setFieldValue("ranges", value);
          }}
          {...props}
        >
          {options &&
            options.map((range: any, ind: any) => (
              <Option key={ind} value={range.id}>
                {range.name}
              </Option>
            ))}
        </Select>
      </Item>
    )
  );

  const FormikInrangeChallengesSelect = forwardRef(
    (
      {
        error,
        label,
        name,
        onChange,
        onBlur,
        placeholder,
        value,
        options,
        style,
        setFieldValue,
        ...props
      }: any,
      ref
    ) => (
      <Item
        style={style}
        {...(Boolean(error) && { validateStatus: "error", help: error })}
      >
        {!!label && <p className="text-field-label">{label}</p>}

        <Select
          className="form-select-field-custom"
          suffixIcon={<DropDownOutlined />}
          placeholder={placeholder}
          ref={ref}
          value={value}
          onChange={async (value) => {
            await setFieldValue("challenges", value);
          }}
          {...props}
        >
          {options &&
            options.map((challenge: any, ind: any) => (
              <Option key={ind} value={challenge.id}>
                {challenge.name}
              </Option>
            ))}
        </Select>
      </Item>
    )
  );

  const FormikInrangeHoleSelect = forwardRef(
    (
      {
        error,
        label,
        name,
        onChange,
        onBlur,
        placeholder,
        value,
        options,
        style,
        setFieldValue,
        ...props
      }: any,
      ref
    ) => (
      <Item
        style={style}
        {...(Boolean(error) && { validateStatus: "error", help: error })}
      >
        {!!label && <p className="text-field-label">{label}</p>}

        <Select
          className="form-select-field-custom"
          suffixIcon={<DropDownOutlined />}
          placeholder={placeholder}
          ref={ref}
          mode="multiple"
          value={value}
          onChange={async (value) => {
            await setFieldValue("challenges", value);
          }}
          {...props}
        >
          {options &&
            options.map((challenge: any, ind: any) => (
              <Option key={ind} value={challenge.id}>
                {challenge.name}
              </Option>
            ))}
        </Select>
      </Item>
    )
  );

  const FormikInrangeCampaignTypeSelect = forwardRef(
    (
      {
        error,
        label,
        name,
        onChange,
        onBlur,
        placeholder,
        value,
        options,
        style,
        setFieldValue,
        ...props
      }: any,
      ref
    ) => (
      <Item
        style={style}
        {...(Boolean(error) && { validateStatus: "error", help: error })}
      >
        {!!label && <p className="text-field-label">{label}</p>}
        <Select
          className="form-select-field-custom"
          suffixIcon={<DropDownOutlined />}
          placeholder={placeholder}
          ref={ref}
          value={value}
          onChange={async (value) => {
            await setFieldValue("type", value);
          }}
          {...props}
        >
          {options &&
            options.map((challenge: any, ind: any) => (
              <Option key={ind} value={challenge.id}>
                {challenge.name}
              </Option>
            ))}
        </Select>
      </Item>
    )
  );

  const FormikInrangePuttingModeTypeSelect = forwardRef(
    (
      {
        error,
        label,
        name,
        onChange,
        onBlur,
        placeholder,
        value,
        options,
        style,
        setFieldValue,
        ...props
      }: any,
      ref
    ) => (
      <Item
        style={style}
        {...(Boolean(error) && { validateStatus: "error", help: error })}
      >
        {!!label && <p className="text-field-label">{label}</p>}

        <Select
          className="form-select-field-custom"
          suffixIcon={<DropDownOutlined />}
          placeholder={placeholder}
          ref={ref}
          value={value}
          onChange={async (value) => {
            await setFieldValue("puttingMode", value);
          }}
          {...props}
        >
          {options &&
            options.map((challenge: any, ind: any) => (
              <Option key={ind} value={challenge.id}>
                {challenge.name}
              </Option>
            ))}
        </Select>
      </Item>
    )
  );

  const FormikInrangeFlightsSelect = forwardRef(
    (
      {
        error,
        label,
        name,
        onChange,
        onBlur,
        placeholder,
        value,
        options,
        style,
        setFieldValue,
        ...props
      }: any,
      ref
    ) => (
      <Item
        style={style}
        {...(Boolean(error) && { validateStatus: "error", help: error })}
      >
        {!!label && <p className="text-field-label">{label}</p>}

        <Select
          className="form-select-field-custom"
          suffixIcon={<DropDownOutlined />}
          placeholder={placeholder}
          ref={ref}
          disabled
          value={value}
          onChange={async (value) => {
            await setFieldValue("flight_distribution_id", value);
            await dispatch(fetchFlightNames(value));
            await setFieldValue("rewards", undefined);
          }}
          {...props}
        >
          {options &&
            options.map((flightDistribution: any, ind: any) => (
              <Option key={ind} value={flightDistribution.id}>
                {flightDistribution.name}
              </Option>
            ))}
        </Select>
      </Item>
    )
  );

  useEffect(() => {
    //Fetch the event right here and populate the values
    // use current id current Campaign
    window.scrollTo({ top: 0, behavior: "smooth" });
    dispatch(fetchCampaign({ id: currentCampaignId }));
  }, [currentCampaignId, dispatch]);

  //Scrolls to a specific field in a formik form after the submit button
  //is clicked if the required filed was not filled.
  useEffect(() => {
    if (!formikMain.isSubmitting) return;
    if (Object.keys(formikMain.errors).length > 0) {
      document.getElementsByName(Object.keys(formikMain.errors)[0])[0]?.focus();
    }
  }, [formikMain]);

  useEffect(() => {
    dispatch(fetchInrangeRanges({}));
    dispatch(fetchInrangeChallenges({}));
    toast.info(
      <>
        <p>Get started with editing your created campaign right here!</p>
      </>,
      {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        closeButton: false,
        transition: Slide,
        style: { minWidth: 400 },
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderInrangeFlightNames = (flightName: any) => {
    switch (flightName) {
      case "Overall":
        return "Overall";
      case "Female":
        return "Female Overall";
      case "Female Overall":
        return "Female Overall";
      case "Pro":
        return "Pro (<6)";
      case "Challenger":
        return "Challenger (6.1 to 12)";
      case "Avid":
        return "Avid (12.1 to 20)";
      case "Casual":
        return "Casual (20.1 to 30)";
      case "Social":
        return "Social (30.1 to 100)";
      default:
        return "";
    }
  };

  return (
    <MainLayout>
      <>
        <Row>
          <Col xl={20} sm={24}>
            <Divider orientation="left" orientationMargin="0">
              <Text className="heading">View Campaign</Text>
            </Divider>
          </Col>
        </Row>

        <FormikProvider value={formikMain}>
          <form onSubmit={handleSubmit} className="create-event-form">
            <Row>
              <Col xl={8} lg={10} md={12} sm={24} xs={24}>
                <FormikTextField
                  disabled
                  label="CAMPAIGN NAME"
                  autoComplete="off"
                  error={Boolean(errors.name && touched.name) && errors.name}
                  placeholder="Enter your campaign name"
                  {...getFieldProps("name")}
                />
              </Col>
              <Col span={8}></Col>
              <Col span={8}></Col>
            </Row>

            <Row gutter={8}>
              <Col xl={8} lg={10} md={12} sm={24} xs={24}>
                <FormikInrangeRangesSelect
                  disabled
                  label="SELECT RANGE"
                  placeholder={"Select a range"}
                  options={rangesNamesOptions}
                  autoComplete="off"
                  setFieldValue={setFieldValue}
                  error={
                    Boolean(errors.ranges && touched.ranges) &&
                    errors.course.ranges
                  }
                  {...getFieldProps("ranges")}
                />
              </Col>
            </Row>
            <Row gutter={8}>
              <Col xl={8} lg={10} md={12} sm={24} xs={24}>
                <FormikInrangeChallengesSelect
                  disabled
                  label="SELECT CHALLENGE"
                  placeholder={"Select an inrange challenge"}
                  options={challengeNamesOptions}
                  autoComplete="off"
                  setFieldValue={setFieldValue}
                  error={
                    Boolean(errors.challenges && touched.challenges) &&
                    errors.course.challenges
                  }
                  {...getFieldProps("challenges")}
                />
              </Col>
            </Row>
            <Row>
              <Col xl={8} lg={10} md={12} sm={24} xs={24}>
                <Text className="poster-text">CAMPAIGN POSTER</Text>
                <ImageUpload
                  disabled
                  logoBase={posterBase}
                  setLogoBase={setPosterBase}
                  uploadIcon={true}
                />

                <Space style={{ marginTop: 10 }}>
                  {liveCampaignData &&
                    !posterBase &&
                    liveCampaignData.campaign_poster && (
                      <Image
                        style={{
                          width: 80,
                          height: 80,
                          objectFit: "contain",
                          marginRight: 10,
                          marginTop: 36,
                          background:
                            liveCampaignData.campaign_poster === null
                              ? "#141879"
                              : "unset",
                        }}
                        src={
                          liveCampaignData.campaign_poster === null
                            ? ""
                            : liveCampaignData.campaign_poster
                        }
                      />
                    )}
                </Space>
              </Col>
            </Row>

            <Row style={{ marginTop: 30 }}>
              <Col xl={8} lg={10} md={12} sm={24} xs={24}>
                <Text className="poster-text">CAMPAIGN SIGN UP BANNER</Text>
                <ImageUpload
                  logoBase={logoBase}
                  setLogoBase={setLogoBase}
                  uploadIcon={true}
                />
                <Space style={{ marginTop: 10 }}>
                  {liveCampaignData &&
                    !logoBase &&
                    liveCampaignData.signup_banner && (
                      <Image
                        style={{
                          width: 80,
                          height: 80,
                          objectFit: "contain",
                          marginRight: 10,
                          marginTop: 36,
                          background:
                            liveCampaignData.signup_banner === null
                              ? "#141879"
                              : "unset",
                        }}
                        src={
                          liveCampaignData.signup_banner === null
                            ? ""
                            : liveCampaignData.signup_banner
                        }
                      />
                    )}
                </Space>
              </Col>
            </Row>

            <Row gutter={4}>
              <Col xl={8} lg={10} md={12} sm={24} xs={24}>
                <FormikRangePicker
                  disabled
                  format="dddd, MM/DD/YYYY h:mm:ss a"
                  showTime
                  label="EVENT START - END DATE"
                  style={{ marginBottom: 36, marginTop: 30 }}
                  autoComplete="off"
                  setFieldValue={setFieldValue}
                  fieldValue="campaignDates"
                  error={
                    Boolean(errors.campaignDates && touched.campaignDates) &&
                    errors.campaignDates
                  }
                  {...getFieldProps("campaignDates")}
                />
              </Col>
              <Col span={8}></Col>
            </Row>

            <Row>
              <Col xl={8} lg={10} md={12} sm={24} xs={24}>
                <FormikTextField
                  label="RULES"
                  disabled
                  autoComplete="off"
                  error={Boolean(errors.rules && touched.rules) && errors.rules}
                  placeholder="Enter your rules for this campaign"
                  {...getFieldProps("rules")}
                />
              </Col>
            </Row>

            <Row>
              <Col xl={8} lg={10} md={12} sm={24} xs={24}>
                <FormikTextField
                  disabled
                  label="PRIZES"
                  autoComplete="off"
                  error={
                    Boolean(errors.prizes && touched.prizes) && errors.prizes
                  }
                  placeholder="Enter your prizes for this campaign"
                  {...getFieldProps("prizes")}
                />
              </Col>
              <Col span={8}></Col>
              <Col span={8}></Col>
            </Row>

            <Row>
              <Col xl={8} lg={10} md={12} sm={24} xs={24}>
                <FormikTextField
                  disabled
                  label="DESCRIPTION"
                  autoComplete="off"
                  error={
                    Boolean(errors.description && touched.description) &&
                    errors.description
                  }
                  placeholder="Enter your description for this campaign"
                  {...getFieldProps("description")}
                />
              </Col>
              <Col span={8}></Col>
              <Col span={8}></Col>
            </Row>
            {/* new priority and other meta fields */}

            <Row>
              <Col xl={18} sm={24}>
                <Divider
                  orientation="left"
                  orientationMargin="0"
                  style={{ marginTop: 50 }}
                >
                  <Text className="points-heading">Meta</Text>
                </Divider>
              </Col>
            </Row>

            <Row gutter={4}>
              <Col xl={12} lg={10} sm={12} xs={12}>
                <FormikTextField
                  disabled
                  label="THIRD PARTY URL"
                  style={{ marginBottom: 12 }}
                  autoComplete="off"
                  error={
                    Boolean(
                      errors.thirdPartyAuthUrl && touched.thirdPartyAuthUrl
                    ) && errors.thirdPartyAuthUrl
                  }
                  placeholder="Enter third party url"
                  {...getFieldProps("thirdPartyAuthUrl")}
                />
              </Col>
            </Row>
            {selectedChallenge && selectedChallenge.type === "MultiPlayer" && (
              <Row gutter={4}>
                <Col xl={6} lg={6} sm={6} xs={6}>
                  <FormikTextField
                    label="MIN PLAYERS"
                    style={{ marginBottom: 12 }}
                    disabled
                    autoComplete="off"
                    error={
                      Boolean(errors.minPlayers && touched.minPlayers) &&
                      errors.minPlayers
                    }
                    onKeyPress={(event: any) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    placeholder="Enter min players"
                    {...getFieldProps("minPlayers")}
                  />
                </Col>
                <Col xl={6} lg={6} sm={6} xs={6}>
                  <FormikTextField
                    label="MAX PLAYERS"
                    style={{ marginBottom: 12 }}
                    autoComplete="off"
                    disabled
                    error={
                      Boolean(errors.maxPlayers && touched.maxPlayers) &&
                      errors.maxPlayers
                    }
                    onKeyPress={(event: any) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    placeholder="Enter max players"
                    {...getFieldProps("maxPlayers")}
                  />
                </Col>
              </Row>
            )}
            {selectedChallenge && selectedChallenge.isVirtualCourse && (
              <Row gutter={4}>
                <Col xl={6} lg={6} sm={6} xs={6}>
                  <FormikTextField
                    label="MAX MULLIGANS ALLOWED"
                    style={{ marginBottom: 12 }}
                    autoComplete="off"
                    disabled
                    error={
                      Boolean(
                        errors.maxMulligansAllowed &&
                          touched.maxMulligansAllowed
                      ) && errors.maxMulligansAllowed
                    }
                    onKeyPress={(event: any) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    placeholder="Enter max mulligans allowed"
                    {...getFieldProps("maxMulligansAllowed")}
                  />
                </Col>
                <Col xl={6} lg={6} sm={6} xs={6}>
                  <FormikTextField
                    label="MAX SHOTS OVER PAR ALLOWED"
                    style={{ marginBottom: 12 }}
                    autoComplete="off"
                    disabled
                    error={
                      Boolean(
                        errors.maxShotsOverParAllowed &&
                          touched.maxShotsOverParAllowed
                      ) && errors.maxShotsOverParAllowed
                    }
                    onKeyPress={(event: any) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    placeholder="Enter max shots over par allowed"
                    {...getFieldProps("maxShotsOverParAllowed")}
                  />
                </Col>
                <Col xl={4} lg={4} md={6} sm={12} xs={12}>
                  <FormikInrangePuttingModeTypeSelect
                    label="PUTTING MODE"
                    placeholder={"Select putting mode"}
                    options={[
                      { name: "PRO", id: "PRO" },
                      { name: "SPIN", id: "SPIN" },
                    ]}
                    disabled
                    autoComplete="off"
                    setFieldValue={setFieldValue}
                    error={
                      Boolean(errors.puttingMode && touched.puttingMode) &&
                      errors.course.puttingMode
                    }
                    {...getFieldProps("puttingMode")}
                  />
                </Col>
                <Col xl={4} lg={4} md={6} sm={12} xs={12}>
                  <FormikInrangeHoleSelect
                    label="HOLE SELECTION"
                    placeholder={"Select hole selection"}
                    options={[
                      { name: "1", id: "1" },
                      { name: "2", id: "2" },
                      { name: "3", id: "3" },
                      { name: "4", id: "4" },
                      { name: "5", id: "5" },
                      { name: "6", id: "6" },
                      { name: "7", id: "7" },
                      { name: "8", id: "8" },
                      { name: "9", id: "9" },
                    ]}
                    disabled
                    autoComplete="off"
                    setFieldValue={setFieldValue}
                    error={
                      Boolean(errors.holeSelection && touched.holeSelection) &&
                      errors.course.holeSelection
                    }
                    {...getFieldProps("holeSelection")}
                  />
                </Col>
              </Row>
            )}

            {/* Points & awards starts here */}

            <>
              <Row>
                <Col xl={18} sm={24}>
                  <Divider
                    orientation="left"
                    orientationMargin="0"
                    style={{ marginTop: 50 }}
                  >
                    <Text className="points-heading"> Points & Awards </Text>
                  </Divider>
                </Col>
              </Row>

              <Row gutter={4}>
                <Col xl={6} lg={6} sm={6} xs={12}>
                  <FormikTextField
                    disabled
                    label="Entry Fee"
                    style={{ marginBottom: 36 }}
                    autoComplete="off"
                    error={Boolean(errors.cost && touched.cost) && errors.cost}
                    placeholder="Enter Fee (Add 0 if free)"
                    {...getFieldProps("cost")}
                  />
                </Col>
              </Row>
              <Row gutter={4}>
                <Col xl={6} lg={6} sm={6} xs={12}>
                  <FormikTextField
                    disabled
                    label="TOTAL SMASH CASH"
                    style={{ marginBottom: 36 }}
                    autoComplete="off"
                    error={
                      Boolean(errors.smash_points && touched.smash_points) &&
                      errors.smash_points
                    }
                    placeholder="Enter smash cash"
                    {...getFieldProps("smash_points")}
                  />
                </Col>
              </Row>
              <NewFormikRadioInput
                label="Flights"
                style={{
                  marginTop: 12,
                  textTransform: "capitalize",
                }}
                disabled
                option1="Yes"
                value1={true}
                value2={false}
                option2="No"
                autoComplete="off"
                error={
                  Boolean(errors.flights && touched.flights) && errors.flights
                }
                {...getFieldProps("flights")}
              />
              {values.flights && (
                <div>
                  <Row>
                    <Col xl={16} sm={24}>
                      <Divider orientation="left" orientationMargin="0">
                        <Text
                          className="points-heading"
                          style={{ fontWeight: "normal", fontSize: 14 }}
                        >
                          Flights Information
                        </Text>
                      </Divider>
                    </Col>
                  </Row>

                  <Row>
                    <Col xl={8} lg={10} md={12} sm={24} xs={24}>
                      <FormikInrangeFlightsSelect
                        label="SELECT FLIGHT SETUP"
                        placeholder={"Select Flight Setup"}
                        options={flightDistributionOptions}
                        autoComplete="off"
                        setFieldValue={setFieldValue}
                        error={
                          Boolean(
                            errors.flight_distribution_id &&
                              touched.flight_distribution_id
                          ) && errors.flight_distribution_id
                        }
                        {...getFieldProps("flight_distribution_id")}
                      />
                    </Col>
                  </Row>
                </div>
              )}
              <Row>
                <Col xl={16} sm={24}>
                  <Divider orientation="left" orientationMargin="0">
                    <Text
                      className="points-heading"
                      style={{ fontWeight: "normal", fontSize: 14 }}
                    >
                      Manual Entry
                    </Text>
                  </Divider>
                </Col>
              </Row>
              <div>
                <FieldArray
                  name="rewards"
                  render={(arrayHelpers) => (
                    <div
                      className="rewards-table-wrapper"
                      style={{
                        width: 500,
                      }}
                    >
                      <div className="table-columns">
                        {values.flights && (
                          <Text className="column">Flights</Text>
                        )}
                        <Text className="column">Position</Text>
                        <Text className="column">Smash Cash</Text>
                      </div>
                      {values.rewards?.map((formItem: any, index: any) => (
                        <div key={index} className="rewards-table-row">
                          {values.flights && (
                            <div
                              className="input-container"
                              style={{ fontSize: 12 }}
                            >
                              <Field
                                as="select"
                                {...getFieldProps(
                                  `rewards[${index}].flight_id`
                                )}
                                disabled
                                style={{ padding: 0 }}
                                onChange={(e: any) => {
                                  const selectedFlightId = e.target.value;
                                  setFieldValue(
                                    `rewards[${index}].flight_id`,
                                    Number(selectedFlightId)
                                  );
                                  const allRewardsWithSelectedFlightId =
                                    values.rewards?.filter(
                                      (x: any) =>
                                        x.flight_id === Number(selectedFlightId)
                                    );
                                  let largestPosition;
                                  if (
                                    allRewardsWithSelectedFlightId.length > 0
                                  ) {
                                    const allPosMap =
                                      allRewardsWithSelectedFlightId.map(
                                        (x: any) => Number(x.position)
                                      );
                                    largestPosition = Math.max(...allPosMap);
                                    if (largestPosition) {
                                      setFieldValue(
                                        `rewards[${index}].position`,
                                        Number(largestPosition + 1)
                                      );
                                    }
                                  } else {
                                    setFieldValue(
                                      `rewards[${index}].position`,
                                      Number(1)
                                    );
                                  }
                                }}
                              >
                                <option value="" selected disabled hidden>
                                  Select Flight
                                </option>
                                {inrageFlightNameOptions?.map((flight: any) => (
                                  <option key={flight.id} value={flight.id}>
                                  {flight.percentage === null &&
                                    renderInrangeFlightNames(flight.name)}
                                  {flight.percentage &&
                                    `${flight.name} (${
                                      flight.percentage * 100
                                    }%)`}
                                </option>
                                ))}
                              </Field>
                            </div>
                          )}
                          <div className="input-container">
                            <Field
                              {...getFieldProps(`rewards[${index}].position`)}
                              disabled
                            />
                            <div className="error-wrapper">
                              <ErrorMessage
                                className="error-line"
                                name={`rewards[${index}].position`}
                              />
                            </div>
                          </div>

                          <div className="input-container">
                            <Field
                              {...getFieldProps(
                                `rewards[${index}].smash_points`
                              )}
                              disabled
                            />
                            <div className="error-wrapper">
                              <ErrorMessage
                                className="error-line"
                                name={`rewards[${index}].smash_points`}
                              />
                            </div>
                          </div>

                          {values.scoring === "Gross_Net" &&
                            values.event_level === "non_league" && (
                              <div className="input-container">
                                <Field
                                  {...getFieldProps(
                                    `rewards[${index}].gross_world_points`
                                  )}
                                  disabled
                                />
                                <div className="error-wrapper">
                                  <ErrorMessage
                                    className="error-line"
                                    name={`rewards[${index}].gross_world_points`}
                                  />
                                </div>
                              </div>
                            )}
                          {values.scoring === "Gross_Net" &&
                            values.event_level === "non_league" && (
                              <div className="input-container">
                                <Field
                                  {...getFieldProps(
                                    `rewards[${index}].net_world_points`
                                  )}
                                  disabled
                                />
                                <div className="error-wrapper">
                                  <ErrorMessage
                                    className="error-line"
                                    name={`rewards[${index}].net_world_points`}
                                  />
                                </div>
                              </div>
                            )}
                        </div>
                      ))}
                    </div>
                  )}
                />
              </div>

              <Row gutter={4}>
                <Col xl={3} lg={4} sm={6} xs={12}>
                  <FormikTextField
                    disabled
                    label="PRIORITY"
                    style={{ marginBottom: 12 }}
                    autoComplete="off"
                    error={
                      Boolean(errors.priority && touched.priority) &&
                      errors.priority
                    }
                    onKeyPress={(event: any) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    placeholder="Enter priorty number"
                    {...getFieldProps("priority")}
                  />
                </Col>

                <Col xl={4} lg={4} md={6} sm={12} xs={12}>
                  <FormikInrangeCampaignTypeSelect
                    disabled
                    label="SELECT CAMPAIGN TYPE"
                    placeholder={"Select an inrange challenge"}
                    options={[{ name: "OPEN", id: "OPEN" }]}
                    autoComplete="off"
                    setFieldValue={setFieldValue}
                    error={
                      Boolean(errors.type && touched.type) && errors.course.type
                    }
                    {...getFieldProps("type")}
                  />
                </Col>
              </Row>
            </>

            <FormikRadioInput
              label="EVENT ENTRY"
              style={{
                marginBottom: 36,
                textTransform: "capitalize",
              }}
              option1="free"
              disabled
              option2="paid"
              autoComplete="off"
              error={
                Boolean(errors.event_entry && touched.event_entry) &&
                errors.event_entry
              }
              {...getFieldProps("event_entry")}
            />

            {/* Payments And Promos starts here */}
            {values.event_entry === "paid" && (
              <>
                <Row>
                  <Col xl={18} sm={24}>
                    <Divider orientation="left" orientationMargin="0">
                      <Text className="payments-heading">Entry Fee </Text>
                    </Divider>
                  </Col>
                </Row>
                <Row>
                  <Col xl={4} lg={4} md={4} sm={8}>
                    <FormikTextField
                      disabled
                      label="CAMPAIGN FEES"
                      style={{ marginBottom: 36 }}
                      autoComplete="off"
                      error={
                        Boolean(errors.cost && touched.cost) && errors.cost
                      }
                      placeholder="Fee"
                      {...getFieldProps("cost")}
                    />
                  </Col>
                </Row>
              </>
            )}

            <Space direction="horizontal" style={{ marginTop: 60 }}>
              <Link to="/admin/campaigns">
                <Button
                  type="link"
                  size={"middle"}
                  style={{ color: "gray", marginTop: 18 }}
                >
                  Cancel
                </Button>
              </Link>
            </Space>
          </form>
        </FormikProvider>
      </>
    </MainLayout>
  );
};

export default ViewCampaign;
