import { smashersAPI } from "@utils/APIInterceptor";

export async function createCampaignApi(campaignData: any) {
  const accessToken = localStorage.getItem("accessToken");

  const constructedRanges =
    campaignData.ranges &&
    Array.isArray(campaignData.ranges) &&
    campaignData.ranges.map((range: any) => ({
      rangeId: range,
      rangeEnabled: true,
    }));
  const stringRewards = JSON.stringify(campaignData.rewards);
  const stringRanges = JSON.stringify(constructedRanges);

  const finalCampaignData = {
    name: campaignData.name,
    description: campaignData.description,
    prizes: campaignData.prizes,
    rules: campaignData.rules,
    priority: campaignData.priority,
    event_entry: campaignData.event_entry,
    cost: campaignData.event_entry === "paid" ? campaignData.cost : 0,
    smash_points: campaignData.smash_points,
    location_description: campaignData.description,
    campaign_type: campaignData.type,
    ranges: stringRanges,
    campaign_instances: stringRanges,
    challenge: JSON.stringify({
      challengeId: campaignData?.challenges,
    }),
    flights: campaignData.flights,
    flight_distribution_id: campaignData.flight_distribution_id,
    campaign_poster: campaignData.campaign_poster,
    signup_banner: campaignData.signup_banner,
    start_date: campaignData.eventDates[0],
    rewards: stringRewards,
    end_date: campaignData.eventDates[1],
    meta: JSON.stringify({
      thirdPartyAuthUrl: campaignData.thirdPartyAuthUrl,
      organisationId: "test-smashers-on",
      ...(campaignData.minPlayers && { minPlayers: campaignData.minPlayers }),
      ...(campaignData.maxPlayers && { maxPlayers: campaignData.maxPlayers }),
      ...(campaignData.puttingMode && {
        puttingMode: campaignData.puttingMode,
      }),
      ...(campaignData.maxMulligansAllowed && {
        maxMulligansAllowed: campaignData.maxMulligansAllowed,
      }),
      ...(campaignData.maxShotsOverParAllowed && {
        maxShotsOverParAllowed: campaignData.maxShotsOverParAllowed,
      }),
      ...(campaignData.holeSelection && {
        holeSelection: campaignData.holeSelection,
      }),
    }),
  };

  const data: any = await smashersAPI(accessToken).post(
    "api/v1/admin/campaigns",
    { ...finalCampaignData },
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );
  if (data.error) {
    return {
      data: data,
      error: data.error,
    };
  }
  return {
    data: data,
  };
}

export async function fetchCampaignsApi(payload: any) {
  const accessToken = localStorage.getItem("accessToken");
  const searchTerm = payload.term || "";
  const data: any = await smashersAPI(accessToken).get(
    `api/v1/admin/campaigns?page=${payload.page}&per_page=${payload.per_page}&term=${searchTerm}`
  );
  if (data.error) {
    return {
      data: data,
      error: data.error,
    };
  }
  return {
    data: data,
  };
}

export async function getSingleCampaign(id: any) {
  const accessToken = localStorage.getItem("accessToken");
  const data: any = await smashersAPI(accessToken).get(
    `api/v1/admin/campaigns/${id}`
  );
  if (data.error) {
    return {
      data: data,
      error: data.error,
    };
  }
  return {
    data: data,
  };
}

export async function deleteCampaignApi(id: any) {
  const accessToken = localStorage.getItem("accessToken");
  const data: any = await smashersAPI(accessToken).delete(
    `api/v1/admin/campaigns/${id}`
  );
  if (data.error) {
    return {
      data: data,
      error: data.error,
    };
  }
  return {
    data: data,
  };
}

export async function updateCampaignApi(payload: {
  campaignData: any;
  campaignId: string;
}) {
  const accessToken = localStorage.getItem("accessToken");
  const { campaignData, campaignId } = payload;
  const constructedRanges =
    campaignData.ranges &&
    Array.isArray(campaignData.ranges) &&
    campaignData.ranges.map((range: any) => ({
      rangeId: range,
      rangeEnabled: true,
    }));
  const stringRewards = JSON.stringify(campaignData.rewards);
  const stringRanges = JSON.stringify(constructedRanges);
  const finalCampaignData = {
    name: campaignData.name,
    description: campaignData.description,
    prizes: campaignData.prizes,
    rules: campaignData.rules,
    priority: campaignData.priority,
    event_entry: campaignData.event_entry,
    cost: campaignData.event_entry === "paid" ? campaignData.cost : 0,
    smash_points: campaignData.smash_points,
    location_description: campaignData.description,
    campaign_type: campaignData.type,
    ranges: stringRanges,
    campaign_instances: stringRanges,
    challenge: JSON.stringify({
      challengeId: campaignData?.challenges,
    }),
    ...(!!campaignData.campaign_poster && { campaign_poster: campaignData.campaign_poster }),
    ...(!!campaignData.signup_banner && {
      signup_banner: campaignData.signup_banner,
    }),
    flights: campaignData.flights,
    flight_distribution_id: campaignData.flight_distribution_id,
    start_date: campaignData.campaignDates[0],
    rewards: stringRewards,
    end_date: campaignData.campaignDates[1],
    meta: JSON.stringify({
      thirdPartyAuthUrl: campaignData.thirdPartyAuthUrl,
      organisationId: "test-smashers-on",
      ...(campaignData.minPlayers && { minPlayers: campaignData.minPlayers }),
      ...(campaignData.maxPlayers && { maxPlayers: campaignData.maxPlayers }),
      ...(campaignData.puttingMode && {
        puttingMode: campaignData.puttingMode,
      }),
      ...(campaignData.maxMulligansAllowed && {
        maxMulligansAllowed: campaignData.maxMulligansAllowed,
      }),
      ...(campaignData.maxShotsOverParAllowed && {
        maxShotsOverParAllowed: campaignData.maxShotsOverParAllowed,
      }),
      ...(campaignData.holeSelection && {
        holeSelection: campaignData.holeSelection,
      }),
    }),
  };
  const data: any = await smashersAPI(accessToken).put(
    `api/v1/admin/campaigns/${campaignId}`,
    { ...finalCampaignData },
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );
  if (data.error) {
    return {
      data: data,
      error: data.error,
    };
  }
  return {
    data: data,
  };
}
